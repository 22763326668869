import AppBase from "../../../base/AppBase";
import {Button, Card, Col, DatePicker, Row, Spin} from "antd";
import {useEffect, useState} from "react";
import {getAPI} from "../../../../utils/apiRequest";
import {DEVICE_DATA, DEVICE_REPORTS, DEVICES} from "../../../../constants/api";
import Chart from "react-apexcharts";
import {getDeviceTypeName, makeFileURL} from "../../../../utils/common";
import {
    HddFilled,
    HomeOutlined,
    PhoneOutlined,
    PlusSquareOutlined,
    ScheduleOutlined,
    SunOutlined,
    ThunderboltOutlined
} from "@ant-design/icons";
import LabelValue from "./labelValue";
import LabelValue2 from "./labelValue2";
import moment from "moment";
import LabelValue3 from "./labelValue3";

const DetailedView = (props) => {
    const {RangePicker} = DatePicker;
    const [devices, setDevices] = useState({});
    const [deviceData, setDeviceData] = useState({});
    const [startDate, setStartDate] = useState(moment().subtract(1, 'w'));
    const [endDate, setEndDate] = useState(moment());
    const [graphStartDate, setGraphStartDate] = useState(moment().subtract(1, 'w'));
    const [graphEndDate, setGraphEndDate] = useState(moment());
    const [graphDate, setGraphDate] = useState(moment());
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const [dailyLoading, setDailyLoading] = useState(false);
    const [cumulativeLoading, setCumulativeLoading] = useState(false);
    const [dailyData, setDailyData] = useState([]);
    const [cumulativeData, setCumulativeData] = useState([]);
    const [dailyDate, setDailyDate] = useState([]);
    const [cumulativeDate, setCumulativeDate] = useState([]);

    useEffect(() => {
        loadDevice(props.match?.params?.id);
        loadDeviceData(props.match?.params?.id);
    }, [props.match?.params?.id, props.match?.params?.device_type])

    useEffect(() => {
        loadDeviceDataCumulative();
    }, [graphEndDate])

    useEffect(() => {
        loadDeviceDataToday();
    }, [graphDate])

    const loadDevice = () => {
        setLoading(true);
        let params = {
            device_type: props.match?.params?.device_type,
            id: props.match?.params?.id
        };
        let successFn = function (result) {
            if (result.length > 0) {
                setDevices(result[0]);
            } else {
                props.history.push(props.match?.params?.device_type ? "/devices/" + props.match?.params?.device_type : "/");
            }
            setLoading(false);
        };
        let errorFn = function () {
            setLoading(false);
        };
        getAPI(DEVICES, successFn, errorFn, params);
    }

    const downloadReport = () => {
        setExportLoading(true);
        let params = {
            id: props.match?.params?.id,
            start_date: startDate.format("YYYY-MM-DD"),
            end_date: endDate.format("YYYY-MM-DD")
        }
        let successFn = function (result) {
            window.open(makeFileURL(result.url));
            setExportLoading(false);
        };
        let errorFn = function () {
            setExportLoading(false);
        };
        getAPI(DEVICE_REPORTS, successFn, errorFn, params);
    }

    const loadDeviceData = () => {
        setLoading(true);
        let params = {
            device_type: props.match?.params?.device_type,
            device: props.match?.params?.id
        };
        let successFn = function (result) {
            setDeviceData(result[0]);
            setLoading(false);
        };
        let errorFn = function () {
            setLoading(false);
        };
        getAPI(DEVICE_DATA, successFn, errorFn, params);
    }

    const loadDeviceDataToday = () => {
        setDailyLoading(true);
        let params = {
            device_type: props.match?.params?.device_type,
            device: props.match?.params?.id,
            start_date: graphDate.format("YYYY-MM-DD"),
            end_date: graphDate.format("YYYY-MM-DD"),
            all: true
        };
        let successFn = function (result) {
            let dates = [];
            let data = [];
            for (let i = 0; i < result.length; i++) {
                dates.push(result[i].created_date);
                data.push(result[i].pvt ? result[i].pvt : 0);
            }
            setDailyData(data);
            setDailyDate(dates);
            setDailyLoading(false);
        };
        let errorFn = function () {
            setDailyLoading(false);
        };
        getAPI(DEVICE_DATA, successFn, errorFn, params);
    }

    const loadDeviceDataCumulative = () => {
        setCumulativeLoading(true);
        let params = {
            device_type: props.match?.params?.device_type,
            device: props.match?.params?.id,
            start_date: graphStartDate.format("YYYY-MM-DD"),
            end_date: graphEndDate.format("YYYY-MM-DD"),
            all: true
        };
        let successFn = function (result) {
            let dates = [];
            let data = [];
            for (let i = 0; i < result.length; i++) {
                dates.push(result[i].created_date);
                data.push(result[i].pvc ? result[i].pvc : 0);
            }
            setCumulativeData(data);
            setCumulativeDate(dates);
            setCumulativeLoading(false);
        };
        let errorFn = function () {
            setCumulativeLoading(false);
        };
        getAPI(DEVICE_DATA, successFn, errorFn, params);
    }

    const series = [
        {
            name: "kWh",
            data: dailyData
        }
    ];
    const options = {
        xaxis: {
            categories: dailyDate,
            labels: {
                formatter: function (value) {
                    return moment(value).format("DD/MM/YYYY HH:mm A");
                }
            },
        }
    };

    const series2 = [
        {
            name: "kWh",
            data: cumulativeData
        }
    ];
    const options2 = {
        xaxis: {
            categories: cumulativeDate,
            labels: {
                formatter: function (value) {
                    return moment(value).format("DD/MM/YYYY HH:mm A");
                }
            },
        }
    };

    const series3 = [deviceData?.sig && parseFloat(deviceData?.sig) ? parseFloat(deviceData?.sig) : 0];

    const options3 = {
        chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: '#e7e7e7',
                    strokeWidth: '97%',
                    margin: 5, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2
                    }
                },
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        offsetY: -2,
                        fontSize: '22px'
                    }
                }
            }
        },
        colors: [deviceData?.sig && parseFloat(deviceData?.sig) >= 70 ? '#0496C7' : (parseFloat(deviceData?.sig) >= 30 ? '#FFDF00' : '#990F02')],
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                shadeIntensity: 0.4,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 53, 91]
            },
        },
        labels: [''],
    };

    return <AppBase>
        <Spin spinning={loading}>
            <Row>
                <Col span={8}>
                    <Card className={"card-1-margin"} bodyStyle={{padding: 8}}>
                        <Row>
                            <Col span={2}>
                                <HddFilled style={{fontSize: 36, color: 'grey', marginTop: 10}}/>
                            </Col>
                            <Col span={22} style={{textAlign: 'right'}}>
                                <span style={{fontSize: 10}}>DEVICE INFO</span><br/>
                                <span style={{fontSize: 24}}>{getDeviceTypeName(devices.device_type)}</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 16}}>
                            <Col span={24}>
                                <LabelValue label={"Device ID"} value={devices?.device_id}/>
                                <LabelValue label={"Manufacturer"}
                                            value={devices?.manufacturer ? devices?.manufacturer : '--'}/>
                                <LabelValue label={"Rating"} value={devices?.rating}/>
                                <LabelValue label={"Online From"}
                                            value={deviceData?.device_extra_data?.online_from ? moment(deviceData?.device_extra_data?.online_from).format("DD/MM/YYYY LTS") : '--'}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card className={"card-1-margin"} bodyStyle={{padding: 8}}>
                        <Row>
                            <Col span={2}>
                                <HomeOutlined style={{fontSize: 36, color: 'grey', marginTop: 10}}/>
                            </Col>
                            <Col span={22} style={{textAlign: 'right'}}>
                                <span style={{fontSize: 10}}>SITE INFO</span><br/>
                                <span style={{fontSize: 24}}>{devices?.site_data?.name}</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 16}}>
                            <Col span={24}>
                                <br/>
                                <LabelValue label={"Address"} value={devices?.site_data?.address}/>
                                <LabelValue label={"Temp."} value={"32°C dummy"}/>
                                <LabelValue label={"Humidity"} value={"56% dummy"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card className={"card-1-margin"} bodyStyle={{padding: 8}}>
                        <Row>
                            <Col span={2}>
                                <PhoneOutlined style={{fontSize: 36, color: 'grey', marginTop: 10}}/>
                            </Col>
                            <Col span={22} style={{textAlign: 'right'}}>
                                <span style={{fontSize: 10}}>RMU INFO</span><br/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 6}}>
                            <Col span={15}>
                                <LabelValue label={"Network"} value={deviceData?.nwt ? deviceData?.nwt : '--'}/>
                                <LabelValue label={"IMEI"} value={deviceData?.imei ? deviceData?.imei : '--'}/>
                                <LabelValue label={"FW Version"} value={"1.0.3_1 dummy"}/>
                                <LabelValue label={"Status"}
                                            value={(new Date() - new Date(deviceData?.created_date)) < (24 * 60 * 60 * 1000) ? "Online" : "Offline"}/>
                                <LabelValue label={"Updated"}
                                            value={deviceData?.created_date ? moment(deviceData?.created_date).fromNow() : "--"}/>
                            </Col>
                            <Col span={9}>
                                <Chart
                                    options={options3}
                                    series={series3}
                                    type="radialBar"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {devices.device_type === '3P' ? <Row>
                <Col span={6}>
                    <Card className={"card-1-margin"} bodyStyle={{padding: 8}}>
                        <Row>
                            <Col span={2}>
                                <SunOutlined style={{fontSize: 36, color: 'grey', marginTop: 10}}/>
                            </Col>
                            <Col span={22} style={{textAlign: 'right'}}>
                                <span style={{fontSize: 10}}>SOLAR PV</span><br/>
                                <span
                                    style={{fontSize: 24}}>{((deviceData?.pv1 ? deviceData?.pv1 : 0) * (deviceData?.pi1 ? deviceData?.pi1 : 0) / 1000).toFixed(2)}, {((deviceData?.pv2 ? deviceData?.pv2 : 0) * (deviceData?.pi2 ? deviceData?.pi2 : 0) / 1000).toFixed(2)}, {((deviceData?.pv3 ? deviceData?.pv3 : 0) * (deviceData?.pi3 ? deviceData?.pi3 : 0) / 1000).toFixed(2)}kW</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 16}}>
                            <Col span={12}>
                                <LabelValue3 label={"Voltage Ch-1"}
                                             value={(deviceData?.pv1 ? deviceData?.pv1 : '0.0') + "V"}/>
                                <LabelValue3 label={"Current Ch-1"}
                                             value={(deviceData?.pi1 ? deviceData?.pi1 : '0.0') + "A"}/>
                                <LabelValue3 label={"Voltage Ch-2"}
                                             value={(deviceData?.pv2 ? deviceData?.pv2 : '0.0') + "V"}/>
                                <LabelValue3 label={"Current Ch-2"}
                                             value={(deviceData?.pi2 ? deviceData?.pi2 : '0.0') + "A"}/>
                                <LabelValue3 label={"Voltage Ch-3"}
                                             value={(deviceData?.pv3 ? deviceData?.pv3 : '0.0') + "V"}/>
                                <LabelValue3 label={"Current Ch-3"}
                                             value={(deviceData?.pi3 ? deviceData?.pi3 : '0.0') + "A"}/>
                            </Col>
                            <Col span={12}>
                                <LabelValue3 label={"Irr"}
                                             value={(deviceData?.irr ? deviceData?.irr : '0.0') + "W/m/m"}/>
                                <LabelValue3 label={"Cell Temp"}
                                             value={(deviceData?.ctp ? deviceData?.ctp : '0.0') + "°C"}/>
                                <LabelValue3 label={"Today"}
                                             value={(deviceData?.ptg ? deviceData?.ptg : '0.0') + "kWh"}/>
                                <LabelValue3 label={"Cumulative"}
                                             value={(deviceData?.pce ? deviceData?.pce : '0.0') + "kWh"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={9}>
                    <Card className={"card-1-margin"} bodyStyle={{padding: 8}}>
                        <Row>
                            <Col span={2}>
                                <ThunderboltOutlined style={{fontSize: 36, color: 'grey', marginTop: 10}}/>
                            </Col>
                            <Col span={22} style={{textAlign: 'right'}}>
                                <span style={{fontSize: 10}}>GRID</span><br/>
                                <span
                                    style={{fontSize: 24}}>{((deviceData?.gvr ? deviceData?.gvr : 0) * (deviceData?.gcr ? deviceData?.gcr : 0) / 1000).toFixed(2)}, {((deviceData?.gvy ? deviceData?.gvy : 0) * (deviceData?.gcy ? deviceData?.gcy : 0) / 1000).toFixed(2)}, {((deviceData?.gvb ? deviceData?.gvb : 0) * (deviceData?.gcb ? deviceData?.gcb : 0) / 1000).toFixed(2)}kW</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 16}}>
                            <Col span={12}>
                                <LabelValue3 label={"Grid Status"} value={deviceData?.gst ? deviceData?.gst : 'NA'}/>
                                <LabelValue3 label={"Voltage RYB"}
                                             value={(deviceData?.gvr ? deviceData?.gvr : '0.0') + ", " + (deviceData?.gvy ? deviceData?.gvy : '0.0') + ", " + (deviceData?.gvb ? deviceData?.gvb : '0.0') + "V"}/>
                                <LabelValue3 label={"Current RYB"}
                                             value={(deviceData?.gcr ? deviceData?.gcr : '0.0') + ", " + (deviceData?.gcy ? deviceData?.gcy : '0.0') + ", " + (deviceData?.gcb ? deviceData?.gcb : '0.0') + "A"}/>
                                <LabelValue3 label={"Frequency RYB"}
                                             value={(deviceData?.gfr ? deviceData?.gfr : '0.0') + ", " + (deviceData?.gfy ? deviceData?.gfy : '0.0') + ", " + (deviceData?.gfb ? deviceData?.gfb : '0.0') + "Hz"}/>
                                <LabelValue3 label={"PF RYB"}
                                             value={(deviceData?.gpr ? deviceData?.gpr : '0.0') + ", " + (deviceData?.gpy ? deviceData?.gpy : '0.0') + ", " + (deviceData?.gpb ? deviceData?.gpb : '0.0')}/>
                                <br/>
                                <br/>
                            </Col>
                            <Col span={12}>
                                <LabelValue2 label={"Irr"}
                                             value={(deviceData?.irr ? deviceData?.irr : '0.0') + "W/m/m"}/>
                                <LabelValue2 label={"Cell Temp"}
                                             value={(deviceData?.ctp ? deviceData?.ctp : '0.0') + "°C"}/>
                                <LabelValue2 label={"Today"}
                                             value={(deviceData?.ptg ? deviceData?.ptg : '0.0') + "kWh"}/>
                                <LabelValue2 label={"Cumulative"}
                                             value={(deviceData?.pce ? deviceData?.pce : '0.0') + "kWh"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={3}>
                    <Card className={"card-1-margin"} bodyStyle={{padding: 8}}>
                        <Row>
                            <Col span={2}>
                                <ScheduleOutlined style={{fontSize: 36, color: 'grey', marginTop: 10}}/>
                            </Col>
                            <Col span={22} style={{textAlign: 'right'}}>
                                <span style={{fontSize: 10}}>INVERTER</span><br/>
                                <span style={{fontSize: 24}}>Dummy kW</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 16}}>
                            <Col span={24}>
                                <LabelValue3 label={"Voltage RYB"}
                                             value={(deviceData?.ivr ? deviceData?.ivr : '0.0') + ", " + (deviceData?.ivy ? deviceData?.ivy : '0.0') + ", " + (deviceData?.ivb ? deviceData?.ivb : '0.0') + "V"}/>
                                <LabelValue3 label={"Current RYB"} value={"0.0, 0.0, DummyA"}/>
                                <LabelValue3 label={"Frequency RYB"} value={"0.0, 0.0, DummyHz"}/>
                                <LabelValue3 label={"Power Factor"}
                                             value={"0.0, 0.0, Dummy"}/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card className={"card-1-margin"} bodyStyle={{padding: 8}}>
                        <Row>
                            <Col span={2}>
                                <PlusSquareOutlined style={{fontSize: 36, color: 'grey', marginTop: 10}}/>
                            </Col>
                            <Col span={22} style={{textAlign: 'right'}}>
                                <span style={{fontSize: 10}}>BATTERY</span><br/>
                                <span
                                    style={{fontSize: 24}}>{deviceData?.bcc > deviceData?.bdc ? "CHARGING" : "DISCHARGING"}</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 16}}>
                            <Col span={24}>
                                <LabelValue2 label={"Voltage"} value={"DummmyV"}/>
                                <LabelValue2 label={"Current"}
                                             value={(deviceData?.bcc > deviceData?.bdc ? deviceData.bcc : deviceData.bdc) + "A"}/>
                                <LabelValue2 label={"Mode"} value={deviceData?.imd ? deviceData?.imd : '--'}/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row> : <Row>
                <Col span={12}>
                    <Row>
                        <Col span={8}>
                            <Card className={"card-1-margin"}>
                                <Row>
                                    <Col span={2}>
                                        <SunOutlined style={{fontSize: 36, color: 'grey', marginTop: 10}}/>
                                    </Col>
                                    <Col span={22} style={{textAlign: 'right'}}>
                                        <span style={{fontSize: 10}}>SOLAR PV</span><br/>
                                        <span
                                            style={{fontSize: 24}}>{((deviceData?.pv1 ? deviceData?.pv1 : 0) * (deviceData?.pi1 ? deviceData?.pi1 : 0) / 1000).toFixed(2)}kW</span>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 16}}>
                                    <Col span={24}>
                                        <LabelValue2 label={"Voltage"}
                                                     value={(deviceData?.pv1 ? deviceData?.pv1 : 0) + "V"}/>
                                        <LabelValue2 label={"Current"}
                                                     value={(deviceData?.pi1 ? deviceData?.pi1 : 0) + "A"}/>
                                        <LabelValue2 label={"Today"}
                                                     value={(deviceData?.ptg ? deviceData?.ptg : 0) + " kWh"}/>
                                        <LabelValue2 label={"Monthly Cumulative"}
                                                     value={(deviceData?.pce ? deviceData?.pce : 0) + " kWh"}/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card className={"card-1-margin"}>
                                <Row>
                                    <Col span={2}>
                                        <ThunderboltOutlined style={{fontSize: 36, color: 'grey', marginTop: 10}}/>
                                    </Col>
                                    <Col span={22} style={{textAlign: 'right'}}>
                                        <span style={{fontSize: 10}}>GRID</span><br/>
                                        <span
                                            style={{fontSize: 24}}>{((deviceData?.gvr ? deviceData?.gvr : 0) * (deviceData?.gcr ? deviceData?.gcr : 0) / 1000).toFixed(2)}kW</span>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 16}}>
                                    <Col span={24}>
                                        <LabelValue2 label={"Voltage"}
                                                     value={(deviceData?.gvr ? deviceData?.gvr : 0) + "V"}/>
                                        <LabelValue2 label={"Current"}
                                                     value={(deviceData?.gcr ? deviceData?.gcr : 0) + "A"}/>
                                        <LabelValue2 label={"Frequency"}
                                                     value={(deviceData?.gfr ? deviceData?.gfr : 0) + "Hz"}/>
                                        <LabelValue2 label={"Power Factor"}
                                                     value={(deviceData?.gpr ? deviceData?.gpr : 0)}/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card className={"card-1-margin"}>
                                <Row>
                                    <Col span={2}>
                                        <ThunderboltOutlined style={{fontSize: 36, color: 'grey', marginTop: 10}}/>
                                    </Col>
                                    <Col span={22} style={{textAlign: 'right'}}>
                                        <span style={{fontSize: 10}}>GRID STATUS</span><br/>
                                        <span style={{fontSize: 24}}>{deviceData?.gst ? deviceData?.gst : "NA"}</span>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 16}}>
                                    <Col span={24}>
                                        <LabelValue2 label={"Import Today"}
                                                     value={(deviceData?.git ? deviceData?.git : "0.0") + " kWh"}/>
                                        <LabelValue2 label={"Export Today"}
                                                     value={(deviceData?.get ? deviceData?.get : "0.0") + " kWh"}/>
                                        <br/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={12}>
                            <Card className={"card-1-margin"}>
                                <Row>
                                    <Col span={2}>
                                        <ScheduleOutlined style={{fontSize: 36, color: 'grey', marginTop: 10}}/>
                                    </Col>
                                    <Col span={22} style={{textAlign: 'right'}}>
                                        <span style={{fontSize: 10}}>INVERTER</span><br/>
                                        <span
                                            style={{fontSize: 24}}>{(deviceData?.ivr ? deviceData?.ivr : 0) * (deviceData?.bdc ? deviceData?.bdc : 0)}kW</span>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 16}}>
                                    <Col span={24}>
                                        <LabelValue2 label={"Voltage"}
                                                     value={(deviceData?.ivr ? deviceData?.ivr : 0) + "V"}/>
                                        <LabelValue2 label={"Current"}
                                                     value={(deviceData?.bdc ? deviceData?.bdc : 0) + "A"}/>
                                        <LabelValue2 label={"Frequency"} value={"DummyHz"}/>
                                        <LabelValue2 label={"Power Factor"}
                                                     value={"Dummy"}/>
                                        <br/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card className={"card-1-margin"}>
                                <Row>
                                    <Col span={2}>
                                        <PlusSquareOutlined style={{fontSize: 36, color: 'grey', marginTop: 10}}/>
                                    </Col>
                                    <Col span={22} style={{textAlign: 'right'}}>
                                        <span style={{fontSize: 10}}>BATTERY</span><br/>
                                        <span
                                            style={{fontSize: 24}}>{deviceData?.bcc > deviceData?.bdc ? "CHARGING" : "DISCHARGING"}</span>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 16}}>
                                    <Col span={24}>
                                        <LabelValue2 label={"Voltage"} value={"DummyV"}/>
                                        <LabelValue2 label={"Current"}
                                                     value={(deviceData?.bcc > deviceData?.bdc ? deviceData?.bcc : (deviceData?.bdc ? deviceData?.bdc : 0)) + "A"}/>
                                        <LabelValue2 label={"Mode"} value={deviceData?.imd ? deviceData?.imd : '--'}/>
                                        <br/>
                                        <br/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>}
            <Row>
                <Col span={12}>
                    <Spin spinning={dailyLoading}>
                        <Card className={"card-1-margin"} title={"PV generation today"}
                              extra={<DatePicker format={"DD/MM/YYYY"} allowClear={false} defaultValue={graphDate}
                                                 onChange={(date) => {
                                                     setGraphDate(date);
                                                 }}/>}>
                            <Chart
                                options={options}
                                series={series}
                                type="line"
                            />
                        </Card>
                    </Spin>
                </Col>
                <Col span={12}>
                    <Spin spinning={cumulativeLoading}>
                        <Card className={"card-1-margin"} title={"PV generation cumulative"}
                              extra={<RangePicker format={"DD/MM/YYYY"} allowClear={false} onChange={(date) => {
                                  setGraphStartDate(date[0]);
                                  setGraphEndDate(date[1]);
                              }}
                                                  defaultValue={[graphStartDate, graphEndDate]}/>}>
                            <Chart
                                options={options2}
                                series={series2}
                                type="line"
                            />
                        </Card>
                    </Spin>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Card className={"card-1-margin"} title={"Generate Reports"}>
                        <Spin spinning={exportLoading}>
                            <Row>
                                <Col span={24}><RangePicker allowEmpty={[false, false]} format={"DD/MM/YYYY"}
                                                            defaultValue={[startDate, endDate]}
                                                            onChange={(val) => {
                                                                setStartDate(val[0]);
                                                                setEndDate(val[1])
                                                            }}/></Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{marginTop: 30}}><Button
                                    type={"primary"} onClick={() => downloadReport()}>Generate
                                    Report</Button></Col>
                            </Row>
                        </Spin>
                    </Card>
                </Col>
            </Row>
        </Spin>
    </AppBase>
}

export default DetailedView;