/*** Base URLs **/
export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const API_URL = BACKEND_BASE_URL + '/api';
export const IMAGE_BASE_URL = BACKEND_BASE_URL + '/media';
export const USER_LOGIN = 'token/';
export const USER_LOGOUT = 'users/logout/';
export const USER_CLONE = 'user_clone/';
export const CHANGE_PASSWORD = 'users/user/password_change/';
export const RESET_PASSWORD_MAIL = 'users/user_reset_mail/';
export const RESET_PASSWORD = 'users/reset_password/';

export const REFRESH_API = "token/refresh/";
export const DEVICES = "devices/";
export const DEVICE_DATA = "device-data/";
export const DEVICE_REPORTS = "export-device-data/";
