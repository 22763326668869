import axios from "axios";
import {handleErrorResponse, makeURL} from "../common";
import lockr from "lockr";
import {AUTH_TOKEN, REFRESH_TOKEN} from "../../constants/dataKeys";
import {_get} from "../lodashUtils";
import {logErrorToSlackChannel} from "../../crashHandling/utils/crashHandlingUtils";
import {REFRESH_API} from "../../constants/api";
import jwt_decode from "jwt-decode";
import {ORGANIZATION} from "../../redux/reduxConstant";
import {cloneUser} from "../../redux/actions/authReduxActions";

export const validateJWT = (token) => {
    let claims;
    try {
        claims = jwt_decode(token);
    } catch (e) {
        clearStorage();
        // window.location.reload();
    }
    const expirationTimeInSeconds = claims?.exp * 1000;
    const now = new Date();
    return expirationTimeInSeconds >= now.getTime();
};

export const getAuthToken = async function () {
    let token = lockr.get(AUTH_TOKEN);

    const isValid = validateJWT(token);

    if (token && !isValid) {
        await axios({
            method: "post",
            url: makeURL(REFRESH_API),
            data: {refresh: lockr.get(REFRESH_TOKEN)},
        })
            .then(function (response) {
                let data = response.data;
                token = data.access
                lockr.set('refresh', data.refresh)
                lockr.set('access', data.access)

            })
            .catch(async function (error) {
                handleErrorResponse(error);
            });
    }
    return token;
};

export const clearStorage = function () {
    localStorage.clear();
};

export const putAPI = async function (
    URL,
    data,
    successFn,
    errorFn,
    headerConfig = {}
) {
    let token = await getAuthToken();
    let authHeaders = {};
    if (token) {
        authHeaders.Authorization = `Bearer ${token}`;
    }
    axios({
        method: "put",
        url: makeURL(URL),
        data: data,
        headers: {
            ...authHeaders,
            ...headerConfig,
        },
    })
        .then(function (response) {
            let data = response.data;
            successFn(data);
        })
        .catch(function (error) {
            if (_get(error, "request.status") === 500) {
                let errorInfo;
                logErrorToSlackChannel(
                    JSON.stringify(data),
                    (errorInfo = {componentStack: _get(error, "request.status")})
                );
            }
            handleErrorResponse(error);
            errorFn(error.response.data);
        });
};

export const postAPI = async function (
    URL,
    data,
    successFn,
    errorFn,
    headerConfig = {}
) {
    let token = await getAuthToken();
    let authHeaders = {};
    if (token) {
        authHeaders.Authorization = `${token}`;
    }
    axios({
        method: "post",
        url: makeURL(URL),
        data: data,
        headers: {
            ...authHeaders,
            ...headerConfig,
        },
    })
        .then(function (response) {
            let data = response.data;
            successFn(data);
        })
        .catch(function (error) {
            if (_get(error, "request.status") === 500) {
                let errorInfo;
                logErrorToSlackChannel(
                    JSON.stringify(data),
                    (errorInfo = {componentStack: _get(error, "request.status")})
                );
            }
            handleErrorResponse(error);
            errorFn(error?.response?.data);
        });
};
export const postOuterAPI = async function (
    URL,
    data,
    successFn,
    errorFn,
    headerConfig = {}
) {
    axios({
        method: "post",
        url: URL,
        data: data,
        headers: {
            ...headerConfig,
        },
    })
        .then(async function (response) {
            let data = response.data;
            await successFn(data);
        })
        .catch(async function (error) {
            handleErrorResponse(error);
            await errorFn(error.response.data);
        });
};

export const getAPI = async function (URL, successFn, errorFn, params = {}) {
    let token = await getAuthToken();
    let authHeaders = {};
    if (token) {
        authHeaders.Authorization = `Bearer ${token}`;
        authHeaders.organization = lockr.get(ORGANIZATION);
    }
    axios({
        method: "get",
        url: makeURL(URL),
        headers: {
            ...authHeaders,
        },
        params: params,
    })
        .then(function (response) {
            let data = response.data;
            successFn(data);
        })
        .catch(function (error) {
            handleErrorResponse(error);
            errorFn(error);
        });
};

export const deleteAPI = function (URL, successFn, errorFn) {
    axios({
        method: "delete",
        url: makeURL(URL),
        headers: {
            // Authorization: 'Bearer ' + getAuthToken()
        },
    })
        .then(function (response) {
            let data = response.data;
            successFn(data);
        })
        .catch(function (error) {
            handleErrorResponse(error);
            errorFn();
        });
};

export const convertRouteSearchToObj = function (searchString) {
    if (searchString) {
        let returnObj = {};
        let searchStringAfterTrim = searchString.substr(1);
        let searchStringGroups = searchStringAfterTrim.split("&");
        searchStringGroups.forEach(function (pairString) {
            let valuePair = pairString.split("=");
            returnObj[valuePair[0]] = valuePair[1];
        });
        return returnObj;
    }
    return {};
};

export const postWithOutTokenAPI = function (
    URL,
    data,
    successFn,
    errorFn,
    headerConfig = {}
) {
    axios({
        method: "post",
        url: makeURL(URL),
        data: data,
        headers: {
            ...headerConfig,
        },
    })
        .then(function (response) {
            let data = response.data;
            successFn(data);
        })
        .catch(function (error) {
            handleErrorResponse(error);
            errorFn(error.response.data);
        });
};

export const putOuterAPI = function (URL, data, successFn, errorFn, headerConfig = {}) {
    axios({
        method: 'put',
        url: URL,
        data,
        headers: {

            ...headerConfig
        },
    }).then(function (response) {
        let data = response.data;
        successFn(data);
    }).catch(function (error) {
        handleErrorResponse(error);
        errorFn(_get(error, 'response.data'));
    });
};