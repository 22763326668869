import {Col, Row, Typography} from "antd";

const LabelValue2 = (props) => {
    return <Row>
        <Col span={12}>
            <Typography className={'text-bold'} align={'left'}>
                {props.label}:
            </Typography>
        </Col>
        <Col span={12}>
            <Typography> &nbsp;{props.value}</Typography>
        </Col>
    </Row>
}

export default LabelValue2;