import {useEffect, useRef, useState} from "react";
import AppBase from "../../../base/AppBase";
import {Button, Input, Space, Table, Typography} from "antd";
import {EyeOutlined, SearchOutlined} from "@ant-design/icons";
import {getAPI} from "../../../../utils/apiRequest";
import {DEVICES} from "../../../../constants/api";
import {Link} from "react-router-dom";

const DevicesView = (props) => {
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm();
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => (
            text
        ),
    });

    useEffect(() => {
        loadDeviceData();
    }, [props.device_type])

    const loadDeviceData = () => {
        setLoading(true);
        let successFn = function (result) {
            setDevices(result);
            setLoading(false);
        };
        let errorFn = function () {
            setLoading(false);
        };
        getAPI(DEVICES, successFn, errorFn, {device_type: props.device_type});
    }

    let columns = [
        {
            title: 'S.No.',
            key: 'sno',
            render: (item, record, index) => devices.indexOf(record) + 1,
        },
        {
            title: 'Mobile No.',
            dataIndex: 'mobile',
            key: 'mobile',
            sorter: {
                compare: (a, b) => a.mobile - b.mobile
            },
            ...getColumnSearchProps('mobile')
        },
        {
            title: 'Device Type',
            dataIndex: 'device_type',
            key: 'device_type',
        },
        {
            title: 'Rating',
            key: 'rating',
            dataIndex: 'rating',
            sorter: {
                compare: (a, b) => a.rating - b.rating
            },
            ...getColumnSearchProps('rating')
        },
        {
            title: 'Site Name',
            dataIndex: 'site',
            key: 'site',
            render: (item, record, index) => <span>{record.site_data?.name ? record.site_data?.name : '--'}</span>,
        },
        {
            title: 'Site Address',
            dataIndex: 'site',
            key: 'site',
            render: (item, record, index) =>
                <span>{record.site_data?.address ? record.site_data?.address : '--'}</span>,
        },
        {
            title: 'Action',
            dataIndex: 'actioon',
            key: 'action',
            render: (item, record, index) => <Link
                to={'/devices/' + record.id + '/details/' + props.device_type}><Button
                icon={<EyeOutlined/>} size={"small"}>View Details</Button></Link>,
        },
    ];
    return <AppBase>
        <Typography.Title style={{fontSize: 26}}>My 1P Devices</Typography.Title>
        <Table columns={columns} dataSource={devices} loading={loading} pagination={false}/>
    </AppBase>;
}

export default DevicesView;