import React from 'react';
import { Helmet } from 'react-helmet';
import { PageHeader, Typography } from 'antd';

const { Paragraph } = Typography;


const PageHeadingAndMetaDetails = ({ ...results }) => {
  const { title, description } = results;
  const titleString = `${title ? `${title} | ` : ''}Statcon`;
  const descriptionString = description;
  const keywordString = 'Keyword';
  return (
    <div>
      <Helmet>
        <title>{titleString} </title>
        <link rel='manifest' href='/manifest.json' />
        <link rel='icon' href='/favicon.png' />
        <link rel='apple-touch-icon' href='/favicon.png' />
        <meta httpEquiv='content-language' content='en-us' />
        <meta name='title' content={titleString} />
        <meta property='keywords' content={keywordString} />
        <meta property='description'
              content={descriptionString} />
        <meta property='og:title' content={titleString} />
        <meta
          property='og:description'
          content={descriptionString}
        />
        <meta property='og:type' content={'website'} />
        <meta property='twitter:card' content={'summary'} />
        <meta
          property='twitter:creator'
          content={'Pagespeed Insights'}
        />
        <meta property='twitter:title' content={titleString} />
        <meta
          property='twitter:description'
          content={descriptionString}
        />
      </Helmet>
      <PageHeader
        className='site-page-header'
        title={results.title}
        // subTitle= {results.subtitle}
      />

      <Paragraph
        ellipsis={{
          rows: 2,
          expandable: true,
          symbol: 'more',
        }}>
        {descriptionString}

      </Paragraph>

    </div>
  );
};

export default PageHeadingAndMetaDetails;
