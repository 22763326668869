import { AUTH_LOGIN_TYPE, AUTH_LOGOUT_TYPE, AUTH_USER_DATA } from '../reduxConstant';

import configureStore from '../store';
import { getAPI } from '../../utils/apiRequest';
import { USER_CLONE } from '../../constants/api';

const persistantStore = configureStore();
let { store } = persistantStore;

export const loginUserAuthAction = (userData) => (dispatch, getState) => {
  return dispatch({
    type: AUTH_LOGIN_TYPE,
    payload: userData,
  });
};

export const logoutUserAuthAction = (userData) => (dispatch, getState) => {
  return dispatch({
    type: AUTH_LOGOUT_TYPE,
    payload: userData,
  });
};

export const cloneUser = () => {
  let successFn = function(data) {
    store.dispatch({
      type: AUTH_USER_DATA,
      payload: data,
    });
  };
  let errorFn = function() {
  };
  getAPI(USER_CLONE, successFn, errorFn);
};
