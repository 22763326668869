import React, {Component} from 'react';
import {Avatar, Col, Dropdown, Layout, Menu, Row, Space} from 'antd';
import {
    LogoutOutlined,
    UnlockOutlined,
    UserOutlined
} from '@ant-design/icons';
import {_get} from '../../../utils/lodashUtils';
import {globalSiderCollapsed} from '../../../redux/actions/globalReducActions';
import {logoutUserAuthAction} from '../../../redux/actions/authReduxActions';
import {connect} from 'react-redux';
import {USER_CLONE} from '../../../constants/api';
import {getAPI} from '../../../utils/apiRequest';
import {Link} from 'react-router-dom';
import {makeFileURL} from '../../../utils/common';
import {withRouter} from 'react-router';
import Logo from '../../../logo.png'
import Lockr from "lockr";
import {ORGANIZATION} from "../../../redux/reduxConstant";

const {Header} = Layout;
export let userNameStr1 = "";

class AppHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userNameStr: ''
        };
    }


    cloneUser = () => {
        let that = this;
        let successFn = function (data) {
            Lockr.set(ORGANIZATION, data?.organization);
            that.setState({
                userNameStr: data?.first_name
            });
            userNameStr1 = data.first_name
        };
        let errorFn = function () {
        };
        getAPI(USER_CLONE,successFn, errorFn);
    };

    componentDidMount() {
        this.cloneUser();
    }

    logOutUser = () => {
        let that = this;
        that.props.logoutUserAuthAction();
        let successFn = function () {
            that.props.logoutUserAuthAction();
        };
        let errorFn = function () {
        };
        // postAPI(USER_LOGOUT, {token: Lockr.get(AUTH_TOKEN)}, successFn, errorFn);
    };
    onHandleLink = (event) => {

        let {history} = this.props;
        if (event.key === 'logout') {
            this.logOutUser();
        } else {
            history.push(event.key);
        }
    };
    componentWillReceiveProps = async (nextProps, nextContext) => {

        console.log(nextProps, "props", this.props);
    }

    render() {
        let that = this;
        const {siderCollapsed, userImage} = that.props;

        let {history} = this.props;
        let userMenu = (
            <Menu onClick={this.onHandleLink}>
                <Menu.Item icon={<UnlockOutlined/>} key={'/change-password'}>
                    <Link to={`/change-password`}>Change Password</Link>
                </Menu.Item>
                <Menu.Item icon={<LogoutOutlined/>} key={'logout'}>
                    Log Out
                </Menu.Item>
            </Menu>
        );

        return (

            <Header
                className='site-layout-background'
                style={{
                    background: '#fff',
                    boxShadow: 'rgba(38, 50, 69, 0.2) 0px 2px 4px 0px',
                    padding: '0 10px',
                    position: 'fixed',
                    zIndex: 1,
                    width: '100%',
                }}
            >
                <Row align={'center'}>
                    <Col flex={siderCollapsed ? '110px' : '235px'}>
                        <div>
                            {!siderCollapsed ? <span onClick={() => history.push('/')}>
                  <img
                      src={Logo}
                      alt='Statcon'
                      style={{maxWidth: '100%', maxHeight: 55, marginRight: 18}}
                  />

                </span> :
                                <img
                                    src={Logo}
                                    alt='Statcon'
                                    style={{maxWidth: '60%', maxHeight: 55, marginRight: 18}}
                                />
                            }
                        </div>
                    </Col>
                    <Col flex='auto'>

                        <Menu theme="light" mode="horizontal">
                            <Menu.Item key="1">
                                <Link to="/devices/1p">Devices-1P</Link>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <Link to="/devices/3p">Devices-3P</Link>
                            </Menu.Item>
                            <Menu.Item key="3">
                                <Link to="/devices/ie">Devices-IE</Link>
                            </Menu.Item>
                        </Menu>

                    </Col>
                    <Col span={8}>
                        <div style={{float: 'right', padding: '0 10px'}}>
                            <Space align='baseline'>
                                <Dropdown overlay={userMenu}>
                                    <Avatar
                                        icon={<UserOutlined/>}
                                        style={{backgroundColor: '#87d068'}}
                                        src={makeFileURL(userImage)}
                                    />
                                </Dropdown>
                                <span style={{fontWeight: 'bold'}}>{userNameStr1}</span>

                            </Space>
                        </div>
                    </Col>
                </Row>
            </Header>
        );
    }
}

const mapStateToProps = (state) => {
    return {

        route: _get(state, 'global.route') || {},
        siderCollapsed: _get(state, 'global.siderCollapsed'),
        loginUser: _get(state, 'auth.user'),
    };
};
const mapDispatchToProps = {
    globalSiderCollapsed,
    logoutUserAuthAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(AppHeader));
