import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {_get} from './utils/lodashUtils';
import RouteContext from './RouteContext';
import Error404 from './components/common/errors/Error404';
import {globalUpdateRoute} from './redux/actions/globalReducActions';
import LogIn from './components/modules/auth/login';
import {cloneUser} from './redux/actions/authReduxActions';

import ChangePassword from './components/modules/auth/changePassword';
import ResetPassword from './components/modules/auth/resetPassword';
import DevicesView from "./components/modules/devices/devices-view";
import DetailedView from "./components/modules/devices/detailed-view";


class RoutesHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingPermissions: false,
        };
    }

    componentDidMount() {
        let {loggedIn} = this.props;
        this.onRouteChanged();
        if (loggedIn) {
            cloneUser(this);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
        if (this.props.currentSiderMenu !== prevProps.currentSiderMenu) {
            this.props.history.push('/');
        }
    }

    onRouteChanged() {
        this.props.globalUpdateRoute({
            ...this.props.match.params,
            location: this.props.location,
        });
    }

    protectComponent = (Component, render) => {
        if (this.props.loggedIn) {
            return Component;
        }
        if (render)
            return (
                <Redirect
                    to={`/login/?next=${
                        window.location.pathname + window.location.search
                    }`}
                />
            );
        return (
            <Redirect
                to={`/login/?next=${window.location.pathname + window.location.search}`}
            />
        );
    };

    render() {
        let that = this;
        return (
            <RouteContext.Provider value={this.props}>
                <Switch>

                    <Route
                        exact
                        path={['/', '/devices/1p']}
                        render={(route) =>
                            that.protectComponent(<DevicesView device_type={"1P"} {...route} />)
                        }
                    />

                    <Route
                        exact
                        path={['/devices/3p']}
                        render={(route) =>
                            that.protectComponent(<DevicesView device_type={"3P"} {...route} />)
                        }
                    />

                    <Route
                        exact
                        path={['/devices/ie']}
                        render={(route) =>
                            that.protectComponent(<DevicesView device_type={"IE"} {...route} />)
                        }
                    />

                    <Route
                        exact
                        path={['/devices/:id/details/:device_type']}
                        render={(route) =>
                            that.protectComponent(<DetailedView {...route} />)
                        }
                    />


                    <Route exact path='/login' render={(route) => <LogIn {...route} />}/>
                    <Route
                        exact
                        path={'/change-password'}
                        render={(route) =>
                            this.protectComponent(<ChangePassword {...route} />)
                        }
                    />
                    <Route
                        exact
                        path={'/password-reset/:token'}
                        render={(route) => <ResetPassword {...route} />}
                    />
                    <Route render={() => <Error404/>}/>
                </Switch>
            </RouteContext.Provider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: _get(state, 'auth.user'),
        loggedIn: _get(state, 'auth.loggedIn'),
        currentSiderMenu: _get(state, 'global.currentSiderMenu'),
        userRoles: _get(state, 'auth.userRoles', []),
    };
};
const mapDispatchToProps = {
    globalUpdateRoute,
};
export default connect(mapStateToProps, mapDispatchToProps)(RoutesHome);
