import {Col, Row, Typography} from "antd";

const LabelValue = (props) => {
    return <Row>
        <Col span={8}>
            <Typography className={'text-bold'} align={'left'}>
                {props.label}:
            </Typography>
        </Col>
        <Col span={16}>
            <Typography> &nbsp;{props.value}</Typography>
        </Col>
    </Row>
}

export default LabelValue;