import {
  DATE_PICKER,
  DATE_TIME_PICKER,
  INPUT_FIELD,
  TIME_PICKER,
} from './dataKeys';



export const HR_SIDERS = {
  key: 'hr-setting',
  icon: 'SettingOutlined',
  title: 'HR Settings',
  menu: [
    {
      key: 'location-devices',
      linkURL: '/hr/setting/location',
      linkTitle: 'Location Setting',
      subMenu: false,
    },
    {
      key: 'dropdown-devices',
      linkURL: '/hr/setting/drop-down',
      linkTitle: 'Dropdown Settings',
      subMenu: false,
    },
    {
      key: 'dropdown-devices',
      linkURL: '/hr/setting/grievance',
      linkTitle: 'Grievance Settings',
      subMenu: false,
    },
    {
      key: 'holiday-devices',
      linkURL: '/hr/setting/holiday',
      linkTitle: 'Holiday Settings',
      subMenu: false,
    },
    {
      key: 'shift-devices',
      linkURL: '/hr/setting/shift',
      linkTitle: 'Shift Settings',
      subMenu: true,
      subMenuObj: [
        {
          key: 'leave-devices',
          title: 'Leave Settings',
          subMenuArr: [
            {
              key: 'add-leave',
              linkURL: '/hr/setting/leave/add',
              linkTitle: 'Add Leave',
            },
            {
              key: 'leave-quota',
              linkURL: '/hr/setting/leave/leave-quota',
              linkTitle: 'Leave Quota',
            },
            // {key: "od-devices", linkURL: "/hr/setting/leave/od-setting", linkTitle: "OD Settings"},
            {
              key: 'club-rules',
              linkURL: '/hr/setting/leave/club-rules',
              linkTitle: 'Club Rules',
            },
            // {key: "leave-count", linkURL: "/hr/setting/leave/count", linkTitle: "Leave Count"},
          ],
        },
        //     {
        //     key: "attendance", title: "Attendance",
        //     subMenuArr: [
        //         {key: "attendance-devices", linkURL: "/hr/setting/attendance", linkTitle: "Attendance Settings"},
        //         {
        //             key: "attendance-refresh",
        //             linkURL: "/hr/setting/attendance/machine",
        //             linkTitle: "Attendance Refresh"
        //         },
        //         {key: "attendance-edit", linkURL: "/hr/setting/attendance/edit", linkTitle: "Edit Attendance"},
        //     ]
        //
        // }
        {
          key: 'appraisal-devices',
          linkURL: '/hr/setting/appraisal',
          title: 'Appraisal Settings',
          subMenu: true,
          subMenuArr: [
            {
              key: 'assessment-year',
              linkURL: '/hr/setting/assessment/year',
              linkTitle: 'Assessment Year',
            },
            {
              key: 'appraisal-parameters',
              linkURL: '/hr/setting/appraisal/parameters',
              linkTitle: 'Appraisal Parameters',
            },
            {
              key: 'appraisal-comments',
              linkURL: '/hr/setting/appraisal/comments',
              linkTitle: 'Appraisal Comments',
            },
            {
              key: 'appraisal-unlocking',
              linkURL: '/hr/setting/appraisal/unlocking',
              linkTitle: 'Appraisal Unlocking',
            },
            {
              key: 'self-appraisal-unlocking',
              linkURL: '/hr/setting/appraisal/self-unlocking',
              linkTitle: 'Self Appraisal Unlocking',
            },
          ],
        },
      ],
    },

    // {
    //   key: "appraisal-devices",
    //   linkURL: "/hr/setting/appraisal",
    //   linkTitle: "Appraisal Settings",
    //    subMenu: true,
    //    subMenuArr: [
    //             {key: "assessment-year", linkURL: "/hr/setting/assessment/year", linkTitle: "Assessment Year"},
    //             {
    //                 key: "appraisal-parameters",
    //                 linkURL: "/hr/setting/appraisal/parameters",
    //                 linkTitle: "Appraisal parameters"
    //             },
    //         ]
    //   },

    // {key: "assign-no-dues-head", linkURL: "/hr/setting/assign-no-dues-head", linkTitle: "Assign No Dues Head", subMenu: false},
  ],
};

export const HR_MODULUS = 'HR';
export const EMPLOYEE_MODULUS = 'Employee';
export const NO_DUE_HEAD_MODULUS = 'NoDueHead';
export const APPROVAL_MODULUS = 'Approval';
export const ACCOUNTS_MODULUS = 'Account';

export const DANGER_COLOR = '#f50';
export const SUCCESS_COLOR = '#87d068';
export const WARNING_COLOR = '#d0cb68';

export const ACCOUNTS_REPORT_CATEGORIES = [
  {
    name: 'Attendance',
    value: '/accounts/reports/attendance',
    active: true,
  },
];

export const REPORT_CATEGORIES = [
  {
    name: 'Attendance',
    value: '/hr/reports/attendance',
    active: true,
  },
  {
    name: 'Leave',
    value: '/hr/reports/leave',
    active: true,
  },
  {
    name: 'Grievance',
    value: '/hr/reports/grievance',
    active: true,
  },
  {
    name: 'Time Sheet',
    value: '/hr/reports/time-sheet',
    active: true,
  },
  {
    name: 'Employees',
    value: '/hr/reports/employees',
    active: true,
  },
  {
    name: 'Appraisal',
    value: '/hr/reports/appraisal',
    active: true,
  },
];

export const ALPHA_NUMERIC = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];
export const ROMANS_NUMBER = [
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
];
// PRESENT_COLOUR = "black"
// HR_MODIFIED_COLOUR = "green"
// HOLIDAY_COLOUR = "orange"
// ABSENT_COLOUR = "red"
// HALF_DAY_COLOUR = "blue"

export const LABEL_VALUE_FIELD = INPUT_FIELD;
export const HEADING_FIELD = 'HeadingField';
export const DATE = DATE_PICKER;
export const DATE_TIME = DATE_TIME_PICKER;
export const TIME = TIME_PICKER;

export const ASSESSMENT_TYPE = [
  { name: 'FILLED', key: 'filled', value: true },
  { name: 'NOT FILLED', key: 'not_filled', value: false },
];


export const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
export const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

export const TRANSACTION_TYPE = [ 
  {name : "CREDIT", key: "credit", value: "CREDIT" },
  {name : "DEBIT", key: "dedit", value: "DEBIT" }
]

export const PROFESSION_CA_ID = 1;
export const PROFESSION_ADVOCATE_ID = 2;
export const PROFESSION_CS_ID = 3;
export const PROFESSION_CMA_ID = 4;
export const PROFESSION_ACCOUNTANT_ID = 5;

// Upto ₹3 Lacs
// ₹3-₹5 Lacs
// ₹6 - ₹10 Lacs
// ₹10 - ₹15 Lacs
// ₹16 - ₹25 Lacs
// ₹26 Lacs+

export const AMOUNT_RANGES = [ 
  {name : "Upto ₹3 Lacs", key: "Upto ₹3 Lacs", value: "Upto ₹3 Lacs" },
  {name : "₹3-₹5 Lacs", key: "₹3-₹5 Lacst", value: "₹3-₹5 Lacs" },
  {name : "₹6 - ₹10 Lacs", key: "₹6 - ₹10 Lacs", value: "₹6 - ₹10 Lacs" },
  {name : "₹10 - ₹15 Lacs", key: "₹10 - ₹15 Lacs", value: "₹10 - ₹15 Lacs" },
  {name : "₹16 - ₹25 Lacs", key: "₹16 - ₹25 Lacs", value: "₹16 - ₹25 Lacs" },
  {name : "₹26 Lacs+", key: "₹26 Lacs+", value: "₹26 Lacs+" },
]
