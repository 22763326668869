import {Col, Row, Typography} from "antd";

const LabelValue3 = (props) => {
    return <><Row>
        <Col span={24}>
            <Typography className={'text-bold'} align={'left'}>
                {props.label}:
            </Typography>
        </Col>
    </Row>
        <Row>
            <Col span={24}>
                <Typography> &nbsp;{props.value}</Typography>
            </Col>
        </Row></>
}

export default LabelValue3;